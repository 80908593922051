// import external modules
import React, {
  Component,
  lazy,
  Suspense,
} from 'react';

import {
  BrowserRouter,
  Redirect,
  Switch,
} from 'react-router-dom';

import Spinner from '../components/spinner/spinner';
// import internal(own) modules
import FullPageLayout from '../layouts/routes/fullpageRoutes';

/** Page Login*/

const LazyLoginPage = lazy( () =>import("../pages/LoginPage"));

class AuthRouter extends Component {
    render() {
        return (
            // Set the directory path if you are deplying in sub-folder
            <BrowserRouter basename="/">
                <Redirect to='/login'/>
                <Switch>

                    <FullPageLayout
                        exact
                        path="/login"
                        render={matchprops => (
                            <Suspense fallback={<Spinner />}>
                                <LazyLoginPage {...matchprops} />
                            </Suspense>
                        )}
                    />

                </Switch>
            </BrowserRouter>
        );
    }
}

export default AuthRouter;
