import './index.scss';
import 'font-awesome/css/font-awesome.min.css';

// import external modules
import React, {
  lazy,
  Suspense,
} from 'react';
import ReactDOM from 'react-dom';

import { useCookies } from 'react-cookie';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

import AuthRouter from './app/auth';
import Spinner from './components/spinner/spinner';
import { store } from './redux/storeConfig/store';
// import internal(own) modules
import registerServiceWorker from './registerServiceWorker';

const LazyApp = lazy(() => import("./app/app"));

const AppWrapper =()=>{
    const [cookies] = useCookies(null);

    if(cookies.token) {
        if (parseInt(cookies.tokenExpire) <= Date.now()) {
           return <AuthRouter/>
        }else{
            return <MainApp/>
        }
    }

    if(!cookies.token) {
       return <AuthRouter/>
    }
    return <AuthRouter/>
}

const MainApp = () =>(
    <Provider store={store}>
        <Suspense fallback={<Spinner />}>
            <LazyApp />
            <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="top-left"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick/>
        </Suspense>
    </Provider>
)

ReactDOM.render(
  <AppWrapper/>
   ,
   document.getElementById("root")
);
registerServiceWorker();
