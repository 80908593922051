import { reducer as toastrReducer } from 'react-redux-toastr';
// import external modules
import { combineReducers } from 'redux';

import customizer from './customizer/';
import defaultReducer from './default';
import indicateurReducer from './indicateur';
import pageReducer from './page';
import procedureReducer from './procedure';
import actualiteReducer from './actualite';
import rapportReducer from './rapport';
import thematiqueReducer from './thematique';
import organisationReducer from './organisation';
import menuReducer from './menu';
import utilisateurReducer from './utilisateur';
import partenaireReducer from './partenaire';

const rootReducer = combineReducers({
   toastr: toastrReducer, // <- Mounted at toastr.
   customizer: customizer,
   thematique: thematiqueReducer,
   organisation: organisationReducer,
   menu: menuReducer,
   indicateur: indicateurReducer,
   page: pageReducer,
   procedure: procedureReducer,
   actualite: actualiteReducer,
   default: defaultReducer,
   utilisateur: utilisateurReducer,
   rapport: rapportReducer,
   partenaire: partenaireReducer
});

export default rootReducer;
